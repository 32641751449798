import React, { useState } from "react"
import banner from '../../images/banner.png'
import "./Features.scss"

const Features = () => {
    return (
        <section id="features-site" className="page-section">
            <div className="container position-relative zindex-5">
                <div className="row">
                    <div className="col-xl-3 col-md-6 ps-lg-4 pb-4 pt-md-5 py-lg-0 text-center text-md-start img-col">
                        <img src={banner} alt="Image"/>
                    </div>
                    <div className="col-xl-6 col-lg-5 col-md-6 offset-xl-3 offset-lg-1 text-col">
                        <div className="row">
                            <div className="col">
                                <h2 className="text-decorate">Enjoy every moment with special app <b>features.</b></h2>
                            </div>
                        </div>
                        <div className="row gutter-3">
                            <div className="col-6 feat-item">
                                <i className="fas fa-heart feat-icon fa-4x"></i>
                                <span className="feat-desc">Easy way to find love</span>
                            </div>
                            <div className="col-6 feat-item">
                                <i className="fas fa-user-shield feat-icon fa-4x"></i>
                                <span className="feat-desc">We protect your data</span>
                            </div>
                            <div className="col-6 feat-item">
                                <i className="fas fa-volume-up feat-icon fa-4x"></i>
                                <span className="feat-desc">Only voice chat experience</span>
                            </div>
                            <div className="col-6 feat-item">
                                <i className="fas fa-grin-beam feat-icon fa-4x"></i>
                                <span className="feat-desc">Friendly Support</span>
                            </div>
                            <div className="col-6 feat-item">
                                <i className="fas fa-star feat-icon fa-4x"></i>
                                <span className="feat-desc">Premium account option for better experience</span>
                            </div>
                            <div className="col-6 feat-item">
                                <i className="fas fa-headphones-alt feat-icon fa-4x"></i>
                                <span className="feat-desc">Headphones recommended for better experience</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features
import React, { useState } from "react"
import Carousel from "react-bootstrap/Carousel"
import Typed from 'react-typed';
import axios from "axios";
import "./MainSite.scss"
import scr1 from '../../images/screens/src1.png'
import scr2 from '../../images/screens/src2.png'
import scr3 from '../../images/screens/src3.png'
import scr4 from '../../images/screens/src4.png'
import scr5 from '../../images/screens/src5.png'
import scr6 from '../../images/screens/src6.png'

const MainSite = () => {
    const typedStrings = [
        "love",
        "friend",
        "fun"
    ];

    return (
        <section id="main-site" className="page-section  bg-skew bg-skew-primary bg-skew-primary">
            <div className="container position-relative zindex-5">
                <div className="row pt-2">
                    <div className="col-xl-5 col-md-6 ps-lg-4 pb-4 pt-md-5 py-lg-5 text-center text-md-start">
                        <h1 className="promo-text">Are you looking</h1>
                        <h1 className="promo-text">for <u className="type-text"><Typed typeSpeed={120} loop strings={typedStrings}/></u>?</h1>
                        <h1 className="promo-text promo-text-lower">Try TapeMe</h1>
                        <h5 className="moto-text">Get to hear new people all around the world.</h5>
                        <div className="d-sm-flex justify-content-center justify-content-md-start store-buttons">
                            <div className="me-sm-3 mb-3">
                                <a className="btn-market btn-apple" target="_blank" href="https://apps.apple.com/us/app/tape-me/id1620239172" role="button">
                                    <span className="btn-market-subtitle">Download on the</span>
                                    <span className="btn-market-title">App Store</span>
                                </a>
                            </div>
                            <div className="mb-3">
                                <a className="btn-market btn-google" target="_blank" href="https://play.google.com/store/apps/details?id=com.coalsoft.tapeme" role="button">
                                    <span className="btn-market-subtitle">Download on the</span>
                                    <span className="btn-market-title">Google Play</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-6 offset-xl-3 offset-lg-1 pt-lg-3">
                        <div className="frame-phone">
                            <div className="frame-phone-body">
                                <div className="frame-phone-status-bar text-light">
                                    <span className="text-nowrap clock-text">11:11</span>
                                    <span className="text-nowrap text-end"><i className="fa fa-wifi wifi-icon" aria-hidden="true"></i><i className="fa fa-battery-full battery-icon" aria-hidden="true"></i></span>
                                </div>
                                <div className="row justify-content-center">
                                    <Carousel fade nextIcon={<span><i className="fa fa-caret-right fa-2x calouser-icon"></i></span>}
                                    prevIcon={<span><i className="fa fa-caret-left fa-2x calouser-icon" aria-hidden="true"></i></span>} 
                                    className="reference-carousel slide">
                                        <Carousel.Item interval={6000}>
                                            <img className="slider-img" src={scr1} alt="img1"></img>
                                        </Carousel.Item>
                                        <Carousel.Item interval={6000}>
                                            <img className="slider-img" src={scr2} alt="img2"></img>
                                        </Carousel.Item>
                                        <Carousel.Item interval={6000}>
                                            <img className="slider-img" src={scr3} alt="img3"></img>
                                        </Carousel.Item>
                                        <Carousel.Item interval={6000}>
                                            <img className="slider-img" src={scr4} alt="img4"></img>
                                        </Carousel.Item>
                                        <Carousel.Item interval={6000}>
                                            <img className="slider-img" src={scr5} alt="img5"></img>
                                        </Carousel.Item>
                                        <Carousel.Item interval={6000}>
                                            <img className="slider-img" src={scr6} alt="img6"></img>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainSite
import * as React from "react"
import MainSite from "../components/Sections/MainSite"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Features from "../components/Sections/Features"

const IndexPage = () => {
  return (
    <Layout>
      <SEO 
        lang="ENG" 
        title="Tape Me"
        keywords={['badoo, tinder, TapeMe, love, person, geart, sex, date, Dating, datingsite, findlove, tapeme, voicemessage']}/>
      <MainSite/>
      <Features/>
    </Layout>
  )
}

export default IndexPage
